<template>
  <section>
    <div class="container-float">
      <EventsHistoryPanel :connectorId="connectorId" :title="title" />
    </div>
  </section>
</template>

<script>
import EventsHistoryPanel from "@/components/events-history-panel.vue";

export default {
  name: "DashboardHistory",
  components: {
    EventsHistoryPanel
  },
  props: {
    connectorId: {
      type: Number,
      required: false,
      default: null
    },
    title: {
      type: String,
      required: false,
      default: "system_event"
    }
  },
  methods: {
    updateURL(connectorId) {
      if (connectorId && this.$route.path != `/dashboard/log/${connectorId}`)
        this.$router.replace(`/dashboard/log/${connectorId}`);
      else if (!connectorId && this.$route.path != "/dashboard/log")
        this.$router.replace("/dashboard/log");
    }
  },
  beforeCreate() {
    if ((!this.$store.getters["dashboard/deviceList"] || []).length) {
      this.$store.dispatch("fetchEquipmentList");
    }
  },
  mounted() {
    this.$root.$emit("controlSidebar:setContent", null);
  }
};
</script>

<style></style>
